import React from "react"
import data from '../../../data/casinos.json'

import "./popular.scss"

class Popular extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { ids } = this.props;

    function stopParent(e) {
      e.stopPropagation();
    }

    return (
      <div className="popular">
        {ids.map(i => data.map(item => i === item.id && (
          <div className="popular-casino" onClick={()=> window.open(item.url, "_blank")} key={item.id} id={item.id}>
            <img src={item.logo} alt={item.name} title={item.name} />
            <span onClick={stopParent} className="popular-tac" dangerouslySetInnerHTML={{ __html: item.tac }} />
          </div>
        )))}        
      </div>
    )
  }
}

export default Popular