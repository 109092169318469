import { Link } from "gatsby";
import React from "react"
import "./casinos.scss"

class Casino extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { 
      name,
      slug, 
      logo, 
      sellingPointOne, 
      sellingPointTwo, 
      sellingPointThree, 
      url, 
      tac,
    } = this.props;

    return (
      <div className="casino">
          <h4 className="name">
            {name}
          </h4>
          <div className="logo">
            <img src={logo} title={name} alt={name} />
          </div>
          <div className="wrapper">
            <ul>
              <li>{sellingPointOne}</li>
              <li>{sellingPointTwo}</li>
              <li>{sellingPointThree}</li>
            </ul>

            <div className="buttons">
              <Link to={"/" + slug} className="info">
                <span>Info</span>
              </Link>
              <a href={url} className="goto" target="_blank">
                Till {name}
              </a>
            </div>
          </div>
        <div className="tac">
          <p dangerouslySetInnerHTML={{ __html: tac }} />
        </div>
      </div>
    )
  }
}

export default Casino