import React from "react"
import Casinos from "../components/Casinos"
import Popular from "../components/Popular"
import Layout from "../components/Layout"
// import Filter from "../components/Filter"
import Offers from "../components/Offers"
import { Link } from "gatsby"
import SEO from "../components/seo"

import picProviders from '../images/other/online-casino/netent-play-n-go-pragmatic-play.jpg'
import picBankId from '../images/other/online-casino/identifiera-online-med-bankid.jpg'
import picSpelinspektionen from '../images/other/online-casino/online-casinon-kontrolleras-av-spelinspektionen.jpg'
import picUtbud from '../images/other/online-casino/storre-utbud-online.jpg'
import picBetalningar from '../images/other/online-casino/bra-betalningsalternativ-pa-casinon.jpg'

const IndexPage = () => (
    <Layout>
        <SEO 
            title="Online Casino 2021 med Casinolabbet.se"
            description="Allt om casino online i Sverige. Hitta det bästa casinot 2021 och ta del av olika erbjudanden och härliga kampanjer idag!" />

        <div className="content-header">
            <div className="wrapper">
                <h1 className="page-title">Hitta rätt online casino hos <span>Casinolabbet</span></h1>
                <p className="page-description">Välkommen till oss på Casinolabbet, med hjälp av vår online casino guide hjälper vi dig att hitta den rätta casinoupplevelsen. Vi listar endast trygga och säkra nätcasinon som har svensk spellicens så här finner du endast de bästa som finns inom Sveriges gränser. Och för att göra det så enkelt för dig som besöker oss så betygsätter vi alla sajterna och recenserar dem. I våra listor finner du också snabb information om vad spelsajten har för erbjudande och fördelar så du får en snabb genomgång direkt. Allt du behöver veta om online casinon finns här på Casinolabbet. Häng med så letar vi upp rätt spelsida för dig!</p>
                {/* <Filter /> */}
            </div>
        </div>

        <div className="content">
            <div className="block-layout" id="topplista">
                <div className="text full">
                    <h2 className="header-margin">Bästa Casino Online 2021</h2>
                    <p className="center">Listan med bästa casino online 2021 uppdateras kontinuerligt för att ge dig bästa möjliga valmöjligheterna.</p>
                </div>
                <Casinos ids={['0006', '0014', '0003', '0017', '0005', '0025', '0007', '0008', '0009', '0010']} buttonExpand={true}  />
            </div>

            <div className="block-layout">
                <div className="text full">
                    <h2 className="header-margin top">Vad är ett online casino?</h2>
                    <p>Ett online casino är den virtuella motsatsen till ett landbaserat casino. Sedan 1995 har det varit möjligt att spela hos dem och de har varit under ständigt utveckling sedan dess. Så det du besöker idag är månresor bättre än förr och utbuden av spel är otroligt stora. Det är en plats för dig som är minst 18 år att spela om pengar på spännande casinospel under kontrollerade former. De har öppet dygnet runt 365 dagar om året och det finns ett stort utbud av de som riktar sig mot svenska kunder. Spela hos dem var du än befinner dig så länge du har uppkoppling till internet och en smarttelefon, dator eller surfplatta. En del nätcasinon har egna appar också för att göra upplevelsen i mobilen ännu lite bättre.</p>
                </div>
                <div className="text full">
                    <h3>Skillnader mellan ett casino på nätet och landbaserade casino</h3>
                    <p>Det finns otroligt många skillnader mellan ett casino på nätet och ett landbaserat casino. Framförallt så är det som befinner sig på nätet alltid tillgängligt och du behöver inte ta dig någonstans för att besöka det. De har heller inte några kvadratmeter som bestämmer hur stort deras spelutbud kan vara utan deras utbud är gigantiska jämfört med ett landbaserat. Om du besöker ett casino online har du också många gånger möjligheten att ta del av ett välkomsterbjudande något de riktiga inte får ge ut. Något som landbaserade kan bjuda på istället är en social plats där du kan käka middag och umgås.</p>
                    <h4>Nackdelar med att spela på nätet</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id orci eu libero pharetra lacinia vitae sed ipsum. Proin nec sodales urna. Nam fermentum augue feugiat ex commodo, non luctus velit aliquam. Maecenas velit ipsum, posuere nec velit ut, lacinia lacinia elit. Nam lacus metus, facilisis.</p>
                </div>
            </div>

            <div className="block-layout">
                <div className="text full">
                    <h2 className="header-margin top">Svenska regler och lagar gäller för nätcasinon</h2>
                    <p>Eftersom vi nu har en svensk spellicens så finns det också svenska regler och lagar som gäller för nätcasinon som vill synas i Sverige. Allt för att vi svenskar skall få en så trygg vistelse som möjligt när vi besöker casinon online.</p>
                    <img src={picSpelinspektionen} title="Online casinon i Sverige kontrolleras av Spelinspektionen" alt="Online casinon i Sverige kontrolleras av Spelinspektionen" />
                    <p>Och det är just därför vi har valt att endast lista sajter som har svensk spellicens för vi vet det inte försiggår något fuffens hos dem utan de är noga kontrollerade av svenska <a href="https://www.spelinspektionen.se/" target="_blank" className="external">Spelinspektionen</a>.</p>
                </div>
                <div className="text full">
                    <h3>Regler online casinon i Sverige måste förhålla sig till</h3>
                    <p>Det finns väldigt många regler som ett online casino måste förhålla sig till för att få vistas på svenska marknaden. Därför har vi valt att endast lista de som framförallt berör dig som spelare. De som gör att du har en så säker och trevlig vistelse som möjligt när du besöker <Link to="/svenska-casino/">svenska casino</Link>. Den svensk spellicensen har nämligen lagt stor fokus på att spelsajterna skall erbjuda sunt spel och måste därför erbjuda verktyg som kan hjälpa till med det. Läs mer om dessa hjälpmedel här under.</p>
                </div>
                <div className="text half left">
                    <h4>Insättnings- och speltidsgränser</h4>
                    <p>Det första du möts av när du har registrerat ett konto hos ett svenskt online casino är ett fönster där du måste sätta insättningsgränser. Där ska du ställa in vad du som max får sätta in om dagen, i veckan och över en hel månad. Därför bör du ta detta hjälpmedel på allvar så du inte ångrar dig i slutet av månaden. Att spela hos ett casino på nätet skall vara ett nöje och man skall aldrig spela för pengar man inte kan tänka sig att förlora. Att tänka på är att om du vill sänka dina gränser så går ändringen igenom direkt men om du istället höjer dem så får du vänta två dagar innan det gått igenom. En annan funktion som du också kan sätta gränser på är tiden du spenderar hos spelsidan. Så känner du att tiden lätt springer iväg kan du sätta speltidsgränser på ditt konto också.</p>
                </div>
                <div className="text half right">
                    <h4>Max ett erbjudande per kund</h4>
                    <p>Tidigare fanns det inga begränsningar för hur många erbjudanden ett casino fick erbjuda. Det fanns välkomsterbjudande, insättningserbjudande och de som gav ett större antal spins så länge du gjorde en insättning. Mail inkorgen fylldes snabbt upp med massa erbjudanden och det är utan tvekan det bästa knepet för att locka till sig nya kunder eller få befintliga till att spela igen. Detta har dock <i>Spelinspektionen</i> gjort sitt yttersta för att städa bort och därför får svensk licensierade online casinon endast erbjuda ett välkomsterbjudande per kund. Detta gäller även per licens så skulle flera casinon ligga under samma licens går det endast motta ett erbjudande från ett av dem.</p>
                </div>
                <div className="text half left">
                    <h4>Möjlighet att pausa sitt spelande</h4>
                    <p>En annan hjälpsam funktion som du numera finner hos alla svenska spelsidor är att du kan ta en paus från ditt spelande. Detta har de valt att kalla <i>Spelpaus</i> och du hittar funktionen i det samlade verktygsfältet med insättningsgränser och självtest. Så skulle du vilja ta en paus klickar du in dig där och väljer hur länge du vill pausa ditt konto. Allt från 24 timmar till 5 år eller permanent går att välja mellan och är du kund hos flera online casinon och vill pausa från alla gör du det hos <a href="https://www.spelpaus.se/" target="_blank" className="external">Spelpaus.se</a>. En pausning går inte att ångra utan när den valda perioden har löpt ut så öppnas dina konton upp automatiskt och du kan då återuppta ditt spel igen. En grymt bra funktion som tillåter dig med problem att avbryta den negativa trenden.</p>
                </div>
                <div className="text half right">
                    <h4>Mejlutskick och annan kommunikation</h4>
                    <p>Idag måste alla svenska spelsidor erbjuda sina kunder att exkludera sig från mejlutskick och annan kommunikation. Så vill du inte få några emails eller sms från dem är det bara att bocka av utskicken så slipper du det. Tänk dock på att då kan det vara så att du går miste om vad som händer på spelsidan. För ofta innehåller dessa utskick just information om nya kampanjer, spel eller annat intressant som händer där. Så vill du hålla dig uppdaterad på enklast möjliga sätt bör du lämna det ibockat och känner du att det bara är jobbigt med alla utskicken bockar du av det. Tänk också på att ifall du tar en spelpaus så upphör all kommunikation från spelsidorna då de inte får skicka ut något till spelare som har en paus.</p>
                </div>
            </div>

            <div className="block-layout">
                <div className="text full">
                    <h2 className="header-margin top">Säkerhet och hantering av information när man spelar online</h2>
                    <p>Samtliga nätcasinon som du finner hos oss på Casinolabbet erbjuder hög säkerhet och säker hantering av information. Det kan vi garantera eftersom vi endast samarbetar med svensklicensierade sajter som följer de krav på säkerhet som licensen kräver. Sedan är det faktiskt så att många av dem vistas på andra marknader också och har därför mer än en licens och på så sätt har de ytterligare krav på säkerheten. De har också krav från de betalningstjänster som de samarbetar med för att kunna upprätthålla så säkra överföringar som möjligt. Därför om du är svensk bör du spela hos ett online casino med svensk licens så vet du att säkerheten är prio ett och din personinformation förvaras säkert.</p>
                </div>
                <div className="text half left">
                    <h3>BankID identifiering hos online casinon i Sverige</h3>
                    <p>Att knappa in personinformation, användarnamn och ett lösenordet är numera ett minne blott. Desto mindre information som du lämnar ut själv ju säkrare är det. Därför har det blivit mer eller mindre ett krav att du som spelar identifierar dig med <a href="https://www.bankid.com/" target="_blank" className="external">BankID</a> istället och låter tjänsten sköta registreringen av din information automatiskt istället.</p>
                    <img src={picBankId} title="Identifiera online med BankID" alt="Identifiera online med BankID" />
                    <p>Detta har medfört att säkerheten för dig som spelare har ökat rejält och du behöver aldrig oroa dig över att ditt användarnamn eller lösenord hamnar i fel händer. Tjänsten aktiverar du i din internetbank  sedan laddar du ner deras app till din telefon och aktiverar den där också. Sedan är du redo att spela på säkrast möjliga sätt.</p>
                </div>
                <div className="text half right">
                    <h3>Säkra och snabba transaktioner online</h3>
                    <p>Det första man söker efter när man skall göra en överföring online är efter tjänster man känner tillit till. Och som tur är så finns det gott om välkända betalningstjänster som utför säkra och snabba transaktioner till och från casinon på nätet. Några av tjänsterna som du kommer stöta på är <i>Visa</i>, <i>Mastercard</i>, <i>Swish</i>, <i>Trustly</i>, <i>Zimpler</i> och <i>Neteller</i>.</p> 
                    <img src={picBetalningar} title="Bra betalningsalternativ till och från casinon" alt="Bra betalningsalternativ till och från casinon" />
                    <p>Det är en perfekt mix av olika betalningstjänster som utför betalningar på lite olika sätt. Kortbetalningarna <i>Visa</i> och <i>Mastercard</i> och mobilbetalningstjänsten <i>Swish</i> känner du säkert igen sedan tidigare och vet vad de erbjuder i form av betalningar. <i>Trustly</i> däremot syns inte så ofta till i vardagssammanhang utan mest online. Detta är också den tjänst som du kommer stöta på allra mest när du skall spela på nätet. Tjänsten samarbetar med samtliga svenska storbanker och kan på så sätt erbjuda direkta banköverföringar från din bank. De har också krav på att <i>BankID</i> identifikation så du behöver aldrig skriva in några kortnummer eller annat när du använder den. De möjliggör också för spelsidorna att göra snabba transaktioner till sina kunder och är det något vi själva uppskattar så är det ett <Link to="/casino-med-snabba-uttag">casino med snabba uttag</Link>. När man väl har lyckats vinna känns det inte som på riktigt förens pengarna sitter på ens egna bankkonto och det går oftast väldigt fort när man använder <i>Trustly</i>.</p>
                </div>
                <div className="text full">
                    <h3>Online kryptering av information och SSL</h3>
                    <p>När man spelar på ett online casino behöver man ofta skicka personlig och känslig data över nätet. Detta kan bland annat vara ens namn, personnummer, kreditkortsinformation eller lösenord. För att minimera risker för intrång och att ditt personliga data ska hamna i fel händer används det idag något som heter SSL (Secure Sockets Layer). Samtliga casinosidor du hittar hos oss är seriösa och därmed har det här extra skyddet omöjliggör eller åtminstone kraftigt försvårar avlysning av data och trafik som du skickar när spelar på ett casino online.</p>
                </div>
            </div>

            <div className="block-layout">
                <div className="text full">
                    <h2 className="header-margin top">Mest populära casino i Sverige idag</h2>
                    <p>Att sätta fingret på vilket som är de mest populära casino i Sverige idag är ingen lätt uppgift. Det är så många olika saker som spelar in och man kan nästan jämföra dem med färskvaror. Antalet aktiva spelare kan bero på om de kör någon kampanj, reklam eller ifall de lagt till något nytt spel på sin sajt. Därför ser man ofta att populariteten ökar och minskar hela tiden hos de flesta sajterna men självklart finns det de som har ett stadigare antal besökare om dagen också. Därför bör du som besöker oss hålla koll på denna listan för hamnar en sajt här så har de gjort något utöver det vanliga eller händer det något speciellt hos dem.</p>
                </div>

                <Popular ids={['0027', '0063', '0026', '0036', '0071', '0051', '0015', '0060' ]} />

                <div className="text full">
                    <h3 className="header-margin top">Hur många online casino finns det?</h3>
                    <p>På den svenska marknaden finns det en bit över 100 olika online casino att välja mellan. Så man kan säga att vi har en stor marknad för spel och ännu större blir den med varje år som går. Man kan räkna med att det i regel kommer en ny spelsida varannan månad men sedan finns det också de som väljer att lämna också. Detta är så klart en fördel för svenska marknaden då den hela tiden hålls uppdaterad och fräsch med nytt. Och att det finns sajter som lämnar marknaden ser vi inte som något negativt då det oftast beror på att de inte håller måttet. Svenska casinospelare är väl medvetna av vad de vill ha och uppfyller de inte kraven så blir antalet besökare inte speciellt höga. Vi på Casinolabbet älskar att testa på något nytt då de ofta kommer med nya funktioner och annat smått och gott. Så är du som oss och tycker du om att prova på något nytt och vill veta mer om vilka de senaste är så har vi skapat en undersida med endast <Link to="/nya-casino">nya casino</Link> i listan.</p>
                </div>
            </div>

            <div className="block-layout">
                <div className="text full">
                    <h2 className="header-margin top">Utbud av spel när man spelar hos ett online casino</h2>
                    <p>Funktioner och design i all ära men det viktigaste av allt förutom säkerheten är att när man besöker ett online casino skall det finnas ett bra utbud av spel. Och det är just därför allt fler spelsidor har slutat att lägga ner massa tid på fräcka designer och istället lagt mer tid på att presentera sitt spelutbud ännu bättre. Det har också blivit betydligt mer fokus på att du som kund skall kunna hitta dina favoriter enklare och därför ser vi att casinons spelfilter har uppgraderats rejält. Nu kan du oftast sortera fram olika typer av spel som automater, bordsspel, jackpottar eller live spel. Och det finns de som tagit sitt filter till en helt annan nivå och tillåter dig att sortera fram spel från olika utvecklare eller spel med specifika bonusspel, spelformat och funktioner.</p>
                </div>

                <div className="text half left">
                    <h3>Inga begränsningar på utrymme</h3>
                    <p>Varför många casinon online har så otroligt stora utbud har en enkel förklaring och det är att det inte finns några begränsningar på utrymmet. Jämför man dem med ett landbaserat så har de inga kvadratmeter att hålla sig till utan utbudet kan växa hur mycket som helst.</p>
                    <img src={picUtbud} title="Större utbud online" alt="Större utbud online" />
                    <p>Därför ser du ofta att spelsidor du besöker har mer än 700-800 olika spel att välja mellan och att det lanseras nya spel varje vecka. Och detta är också en av de största fördelarna med att spela online att det alltid finns något nytt och fräscht att spela på.</p>
                </div>

                <div className="text half right">
                    <h3>Många olika typer av spel</h3>
                    <p>När du för första gången besöker ett casino på nätet så lär du dig snabbt känna att det finns många olika typer av spel. Det finns garanterat de klassiska bordsspelen roulette och blackjack både i virtuell och live miljö. Men sedan finns det också automater och dessa kommer i en större variation än övriga spel. Inom automater finner du nämligen de gamla hederliga med endast tre hjul som snurrar men även de som har fler hjul och till och med inga hjul alls. Gränserna för vad som räknas som en automat är har tänjts till sitt yttersta och deras funktioner och spelupplägg kan skilja sig enormt mycket.  Antalet vinstlinjer och vinnande kombinationer behöver inte se likadana ut och inte heller antalet hjul som snurrar. Det finns också en ny sorts funktion som kallas <i>Megaways</i> där antalet symboler och vinnande kombinationer ändras för varje snurr du gör. Så därför är det väldigt viktigt att alltid läsa igenom spelet information eller testa dem i demoläge innan man spelar för riktiga pengar. På så sätt kan du undvika att du spenderat riktiga pengar på ett spel du inte riktigt förstår eller tycker är speciellt kul.</p>
                </div>

                <div className="text half left">
                    <h3>Det finns många olika spelleverantörer</h3>
                    <p>Eftersom det är så populärt att spela casinospel så finns det inte bara en hand full av spelleverantörer utan bra många fler än det. Spelvärlden söker alltid efter det senaste och lyckas en leverantör släppa någon ny funktion som senare blir väldigt populär slår deras spel igenom med dunder och brak. Och just därför har vi så många olika spelleverantörer som utvecklar fantastiska casinospel som vi kan ta del av.</p>
                    <img src={picProviders} title="Spelleverantörer - NetEnt, Play'N Go och Pragmatic Play" alt="NetEnt, Play'N Go och Pragmatic Play" />
                    <p>Faktum är faktiskt att flera utav de allra bästa speltillverkarna kommer från Sverige. <i>NetEnt</i>, <i>Play'N Go</i> och <i>Pragmatic Play</i> är alla svenska bolag och är bland de mest populära inom slots. De har genom åren släppt oerhört många spel som prytt topplistor med de mest spelade spelen hos casinon världen över. Därför är det också dessa tre som sajterna skyltar med att de har spel från även om de har 20 andra leverantörer också. Lägg därför alltid på minnet vilken leverantör som ligger bakom ditt nya favoritspel för det behöver inte vara just dessa tre utan det finns väldigt många andra duktiga också.</p>
                </div>

                <div className="text half right">
                    <h3>De vanligaste typer av spel online</h3>
                    <ul>
                        <li>
                            Slots
                            <ul>
                                <li>Videoslots</li>
                                <li>Jackpottslots</li>
                                <li>Megaways</li>
                                <li>Klassiska slots</li>
                            </ul>
                        </li>
                        <li>
                            Livespel
                            <ul>
                                <li>Roulette</li>
                                <li>Blackjack</li>
                                <li>Baccarat</li>
                                <li>Craps</li>
                                <li>Football Studio</li>
                            </ul>
                        </li>
                        <li>Bordsspel</li>
                        <li>
                            Odds & betting
                            <ul>
                                <li>Liveodds</li>
                                <li>Trav och galopp</li>
                            </ul>
                        </li>
                        <li>Virtuella sporter</li>
                        <li>Poker</li>
                    </ul>
                </div>
            </div>

            <div className="block-layout">
                <div className="text full">
                    <h2 className="header-margin top">Casinolabbet hjälper dig hitta rätt</h2>
                    <p>Som vi nämnde tidigare så finns det över 100 olika casinon med svensk spellicens att välja mellan. Är man helt ny är det oerhört svårt att veta vilket som är bra och vilket som är dåligt. Och det är där vi på Casinolabbet kommer in för att guida dig fram till det bästa möjliga alternativet. Alla sajter som vi listar här på sidan har vi testat själva. Vi har provat på deras spelutbud, kontaktat deras kundtjänst och vi har faktiskt också skrivit recensioner om dem. I våra recensioner kan du läsa mer genomgående om vad som erbjuds i form av kampanjer, spelutbud, betalningstjänster och även vad vi tycker de har gjort bra eller mindre bra. Även om det mesta handlar om personliga preferenser kan det alltid vara bra att läsa vad någon annan tycker innan man testar själv. Och om det är en sak vi kan tala om redan här är att de allra flesta online casinon med svensk licens håller väldigt hög standard.</p>
                    <p>OBS! I våran lista med <Link to="#topplista">Bästa casino online 2021</Link> hittar du dem vi tycker är bäst just nu.</p>
                </div>
                <div className="text half left">
                    <h3>Kort om Casinolabbet och vår vision</h3>
                    <p>Vi som driver Casinolabbet.se har många år inom spelbranschen och vår vision är att så gott det går dela med oss av den kunskap vi skaffat oss genom alla år. Och på så sätt ge våra besökare de bästa möjliga förutsättningarna till att hitta det online casino som passar bäst. Vår vision är också att vi skall bjuda på en unik sajt med en användarvänlig miljö som återger relevant information och det senaste nyheterna inom spelbranschen.</p>
                </div>
                <div className="text half right">
                    <h3>Att vara transparent är nyckel</h3>
                    <p>I en värld där det handlar om att få ut mest underhållning och spänning för de pengarna man spenderar på det ser vi det som extra viktigt att vara helt transparenta. Den som besöker oss skall känna förtroende för att den information som finns här stämmer överens. Just därför är vi extra noga när vi betygsätter ett casino och går även igenom vad som är bra eller mindre bra med den. Hade du däremot gått andra vägen och hoppat över ett besök hos oss hade du endast mötts av vad spelsidorna tycker om sin egen produkt och där är det aldrig någon som skriver om några brister.</p>
                </div>
                <div className="text half left">
                    <h3>Vi guidar dig till de bästa erbjudanden</h3>
                    <p>LEn av de de största anledningarna till att spelare får upp ögonen för ett online casino är genom erbjudandet. Det är just därför de allra flesta också har ett erbjudande för att inte gå miste efter de som söker efter dem. Det finns de som ger dig en procentandel av beloppet du sätter in extra på ditt saldo och det finns de som ger dig free spins. Deras gemensamma nämnare är att du får mer att spela för medans de får en ny kund att underhålla. Självklart så kommer dessa gratis pengarna med en hållhake i form av omsättningskrav och andra regler. Eftersom att erbjudande är en så stor punkt i spelbranschen har vi valt att visa erbjudanden direkt i våra listor och vi har även skapat en undersida om just <Link to="/erbjudande/">casino bonusar</Link>. Där kan du läsa mer om vad till exempel ett omsättningskrav är och annat som berör dem.</p>
                </div>
                <div className="text half right">
                    <h3>Smarta filtrerings- och sökfunktioner</h3>
                    <p>Under planeringsstadiet av Casinolabbet.se var ett av de största diskussionsämnena att vi vill erbjuda våra besökare något de inte hittar hos någon annan. För det finns idag väldigt många casinoguider med i princip samma online casinon som representeras. Vad som dock saknas är en användarvänlig miljö där du inte behöver vara Einstein för att kunna navigera dig runt på sajten. Därför har vi lagt ner mycket tid på att skapa smarta filtrerings- och sökfunktioner som gör det enkelt för dig som besöker oss att hitta vad du är ute efter. Sortera fram casinosidor med erbjudanden, BankID, betalningstjänster eller spelutvecklare. Det idag snabbaste möjliga sätt att hitta flera valmöjligheter av online casino som har dina favoriter.</p>
                </div>
            </div>

            <div className="block-layout">
                <div className="text full">
                    <h2 className="header-margin top">Topp 3 casino erbjudanden just nu</h2>
                    <p className="center">Starta resan hos nästa casino på nätet med lite extra i saldot eller några gratis snurr på någon fräck automat. Eftersom det finns så otroligt många erbjudanden att välja mellan har vi valt att lista några av våra favoriter här som erbjuder just det.</p>
                </div>

                <Offers ids={['0001', '0002', '0003' ]} buttonExpand={true} />
            </div>

            <div className="block-layout">
                <div className="text faq">
                    <h2 className="header-margin top bottom">Frågor och svar</h2>
                    <h3>Vad är ett online casino?</h3>
                    <p>Ett online casino är motsvarigheten till ett landbaserat casino som du kan besöka i verkligheten. Det är en plats där du under kontrollerade former kan spela på spännande casinospel om riktiga pengar.</p>
                    <h3>Är det säkert att spela online?</h3>
                    <p>Samtliga casino som du finner hos oss på Casinolabbet är licensierade för att få verka på svenska spelmarknaden. De erbjuder hög säkerhet i form av högt krypterad information och säkra betalningar med världskända betalningstjänster. Deras spels utgång sköts av en oberoende slumpgenerator så inga av dem kan kontrollera vilka som vinner eller vise versa.</p>
                    <h3>Hur registrerar man sig hos ett casino online?</h3>
                    <p>Att registrera sig hos ett casino online är ingen långdragen process. Med hjälp av ditt <i>BankID</i> så kan du skapa ett konto på ett par minuter. Personinformation och identifiering sköts automatiskt och du slipper lämna ut någon personinformation själv.</p>
                    <h3>Hur många online casinon finns det?</h3>
                    <p>Ser man över hela världen så finns det tusentals olika online casinon men tittar man på de som är aktuella för svenska spelare rör det sig om en bit över 100 stycken.</p>
                    <h3>Vad innebär ett casino med svensk licens?</h3>
                    <p>Ett casino med svensk licens är kontrollerade av svenska myndigheten <i>Spelinspektionen</i>. De ser till att svensklicensierade spelsajter sköter det lagligt, erbjuder en säker och trygg spelmiljö och övervakar samtliga noggrant. De har dig som konsument och ditt välmående i fokus och ser till att casinon erbjuder de rätta verktygen till en sund spelmiljö.</p>
                </div>
            </div>
        </div>
    </Layout>   
)

export default IndexPage 