import React from "react"
import Casino from './casino'

import "./casinos.scss"
import data from "../../../data/casinos.json"

class Casinos extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { ids, buttonExpand } = this.props;

    return (
      <div className="casinos">
        {ids.map(i => data.map(item => i === item.id && (
          <Casino 
            key={item.id}
            id={item.id}
            name={item.name} 
            slug={item.slug}
            logo={item.logo}
            sellingPointOne={item.sellingPointOne}
            sellingPointTwo={item.sellingPointTwo}
            sellingPointThree={item.sellingPointThree} 
            url={item.url}
            tac={item.tac}
          />
        )))}
        {buttonExpand && (
          <div className="button-wrapper">
            <a href="/svenska-casino/" className="button">
              Visa alla casino
            </a>
          </div>
        )}
      </div>
    )
  }
}

export default Casinos