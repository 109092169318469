import React from "react"
import data from '../../../data/offers.json'

import "./offers.scss"

class Offers extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { ids, buttonExpand } = this.props;

    function stopParent(e) {
      e.stopPropagation();
    }

    return (
        <div className="offers">
            {ids.map(i => data.map(item => i === item.id && (
                <div className="offer" onClick={()=> window.open(item.url, "_blank")} key={item.id} id={item.id} style={{backgroundColor : item.colorOne, color : item.colorTwo}}>
                    <img src={item.logo} alt={item.name} title={item.name} />
                    <h3><span className="first">{item.lineOne}</span><span className="second">{item.lineTwo}</span></h3>
                    <span onClick={stopParent} className="tac" dangerouslySetInnerHTML={{ __html: item.tac }} />
                </div>
            )))}
        
          {buttonExpand && (
            <div className="button-wrapper">
              <a href="/erbjudande/" className="button">
                Se alla erbjudande
              </a>
            </div>
          )}
        </div>
    )
  }
}

export default Offers